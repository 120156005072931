
import { Vue, Component } from "vue-property-decorator";
import { UserService } from "@/api/UserServiceApi";

import {
  IAccountRegistration,
  ICompanyRegistration,
  IUserRegistration
} from "@/models/AccountRegistration";

import { IErrorDetails } from "@/models/ErrorDetails";
import { ISubscription } from "@/models/Subscription";

import { ILoginDetails } from "@/models/LoginDetails";
import user from "@/store/modules/UserModule";
import InputField from "@/components/InputField.vue";
import Subscription from "@/components/Subscription.vue";
import PageLoader, { PageLoaderState } from "@/components/PageLoader.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

@Component({
  components: {
    InputField,
    Subscription,
    PageLoader,
    TermsAndConditions
  }
})
export default class Register extends Vue {
  errorDetails: IErrorDetails = {
    errors: []
  };

  pageLoaderState = PageLoaderState.Loading;
  picked: boolean = false;
  password: string = "";

  subscription: ISubscription = {
    invoicingMethodId: 0,
    priceModelId: 0,
    subscribedRegions: [],
    subscribedCategories: [],
    discountCodeId: 0
  };

  userRegistration: IUserRegistration = {
    mobilePhone: "",
    email: "",
    password: "",
    acceptedTermsAndConditions: false,
    language: "fi"
  };

  companyRegistration: ICompanyRegistration = {
    companyName: "",
    businessCode: "",
    contactPerson: ""
  };

  async registerUser() {
    this.errorDetails.errors = [];
    this.userRegistration.password = this.password;
    this.userRegistration.language = this.$i18n.locale;
    const accountRegistration: IAccountRegistration = {
      user: this.userRegistration,
      company: this.companyRegistration,
      subscription: this.subscription,
      subscriptionExpiryDate: "",
      createInvoice: true
    };

    await UserService.Register(accountRegistration)
      .then(async response => {
        const userSubmit: ILoginDetails = {
          email: this.userRegistration.email,
          password: this.password,
          rememberMe: false
        };

        await user.login(userSubmit).then(async response => {
          await this.$router.push("/dashboard");
        });
      })
      .catch(error => {
        this.errorDetails = error.response.data as IErrorDetails;
      });
  }

  loading(isLoading: boolean) {
    if (isLoading) {
      this.pageLoaderState = PageLoaderState.Loading;
    } else {
      this.pageLoaderState = PageLoaderState.Completed;
    }
  }

  setSubscription(subscription: ISubscription) {
    this.subscription = subscription;
  }

  updateTermsAndConditionsAcceptance() {
    this.userRegistration.acceptedTermsAndConditions = !this.userRegistration
      .acceptedTermsAndConditions;
  }
}
